<template>
    <div>
        <el-card>
            <nav class="input" style="width: auto">
                <el-input v-model="search.keyword" size="medium" placeholder="优惠券名称、备注" />
            </nav>
            <nav class="input" style="width: 150px">
                <el-button size="small" @click="reset">重置</el-button>
                <el-button type="primary" size="small" @click="subimt">{{ "搜索" }}</el-button>
            </nav>
            <nav class="input" style="width: auto">
                <el-button type="primary" size="small" @click="add">{{ "新建优惠券" }}</el-button>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                <el-table-column prop="id" label="ID" />
                <el-table-column prop="name" label="优惠券名称" />
                <el-table-column prop="discountType" label="优惠价格">
                    <template slot-scope="{ row }">
                        {{ row.discountType == 0 ? `-${row.discountValue}%` : `-${row.discountValue}元` }}
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="类型">
                    <template slot-scope="{ row }">
                        <div>{{ row.type == 0 ? "通用" : row.type == 1 ? "活动" : "VIP会员" }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="beginTime" label="有效时间">
                    <template slot-scope="{ row }">
                        {{ row.beginTime + " 至 " + row.endTime }}
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template slot-scope="{ row }">
                        <el-tag :type="row.status ? 'warning' : 'success'">{{ row.status ? "停止" : "启用" }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="使用/领取/总数">
                    <template slot-scope="{ row }">
                        <span class="click" @click="toReceive(row)">{{
                            `${row.usedNum}/${row.getNum}/${row.number}`
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="remask" label="备注">
                    <template slot-scope="{ row }">
                        <Remark :row="row" url="/admin/Coupon/editRemark"></Remark>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="二维码">
                    <template slot-scope="{ row }">
                        <el-image
                            style="width: 80px"
                            :src="baseurl + yu + row.sunCode"
                            :preview-src-list="[baseurl + yu + row.sunCode]"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="{ row }">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            style="color: skyblue"
                            @click="editor(row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            size="mini"
                            style="color: red"
                            @click="deletes(row)"
                            >删除</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-s-promotion"
                            size="mini"
                            style="color: pink"
                            @click="shelves(row)"
                            >{{ row.status == 0 ? "停用" : "启用" }}</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="search.page"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="search.pageSize"
                layout="total, prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange"
            />
        </el-card>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
import { mapState } from "vuex";
export default {
    mixins: [list],
    data() {
        return {
            search: {
                keyword: "",
                page: 1,
                pageSize: 10,
            },
            form: {
                keyword: "",
                page: 1,
                pageSize: 10,
            },
        };
    },
    created() {
        // this.getlist();
    },
    activated() {
        this.getlist();
    },
    methods: {
        add() {
            this.$router.push("/coupon_add");
        },
        toList(row) {
            this.$router.push({
                path: "/coupon_add",
                query: {
                    id: row.id,
                },
            });
        },
        toReceive(row) {
            this.$router.push({
                path: "/coupon_detail",
                query: {
                    id: row.id,
                },
            });
        },
        editor(row) {
            this.$router.push({
                path: "/coupon_add",
                query: {
                    id: row.id,
                },
            });
        },

        async deletes(row) {
            try {
                await this.$confirm("此操作将永久删除, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
                let { data: res } = await this.$http.delete("/admin/Coupon/delete", {
                    params: {
                        id: row.id,
                    },
                });
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    if (this.total % 10 == 1) {
                        this.search.page--;
                    }
                    this.getlist();
                }
            } catch (error) {
                this.$message({
                    type: "info",
                    message: "已取消删除",
                });
            }
        },
        async shelves(row) {
            // console.log(row.status);
            const { data: res } = await this.$http.post("/admin/Coupon/updateStatus", {
                id: row.id,
                status: row.status ? 0 : 1,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        handleCurrentChange(e) {
            this.search.page = e;
            this.form.page = e;
            this.getlist(this.form);
        },
        async getlist(val) {
            const form = val || this.search;
            let { data: res } = await this.$http.get("/admin/Coupon/getList", {
                params: {
                    ...form,
                },
            });
            this.list = res.data.list;
            this.total = res.data.totalCount;
        },
        subimt() {
            this.form = {
                ...this.search,
            };
            this.getlist();
        },
    },
    computed: {
        ...mapState({
            btn_if: (state) => state.btn_if,
        }),
    },
};
</script>

<style lang="less" scoped>
.click {
    color: #0000ee;
    text-decoration: underline;
    cursor: pointer;
}
</style>
