var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',[_c('nav',{staticClass:"input",staticStyle:{"width":"auto"}},[_c('el-input',{attrs:{"size":"medium","placeholder":"优惠券名称、备注"},model:{value:(_vm.search.keyword),callback:function ($$v) {_vm.$set(_vm.search, "keyword", $$v)},expression:"search.keyword"}})],1),_c('nav',{staticClass:"input",staticStyle:{"width":"150px"}},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.reset}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.subimt}},[_vm._v(_vm._s("搜索"))])],1),_c('nav',{staticClass:"input",staticStyle:{"width":"auto"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.add}},[_vm._v(_vm._s("新建优惠券"))])],1)]),_c('el-card',{staticStyle:{"margin-top":"10px"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","stripe":"","header-cell-style":_vm.rowClass}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID"}}),_c('el-table-column',{attrs:{"prop":"name","label":"优惠券名称"}}),_c('el-table-column',{attrs:{"prop":"discountType","label":"优惠价格"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.discountType == 0 ? ("-" + (row.discountValue) + "%") : ("-" + (row.discountValue) + "元"))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"type","label":"类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.type == 0 ? "通用" : row.type == 1 ? "活动" : "VIP会员"))])]}}])}),_c('el-table-column',{attrs:{"prop":"beginTime","label":"有效时间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.beginTime + " 至 " + row.endTime)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":row.status ? 'warning' : 'success'}},[_vm._v(_vm._s(row.status ? "停止" : "启用"))])]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"使用/领取/总数"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"click",on:{"click":function($event){return _vm.toReceive(row)}}},[_vm._v(_vm._s(((row.usedNum) + "/" + (row.getNum) + "/" + (row.number))))])]}}])}),_c('el-table-column',{attrs:{"prop":"remask","label":"备注"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('Remark',{attrs:{"row":row,"url":"/admin/Coupon/editRemark"}})]}}])}),_c('el-table-column',{attrs:{"prop":"id","label":"二维码"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"80px"},attrs:{"src":_vm.baseurl + _vm.yu + row.sunCode,"preview-src-list":[_vm.baseurl + _vm.yu + row.sunCode]}})]}}])}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticStyle:{"color":"skyblue"},attrs:{"type":"text","icon":"el-icon-edit","size":"mini"},on:{"click":function($event){return _vm.editor(row)}}},[_vm._v("编辑")]),_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text","icon":"el-icon-delete","size":"mini"},on:{"click":function($event){return _vm.deletes(row)}}},[_vm._v("删除")]),_c('el-button',{staticStyle:{"color":"pink"},attrs:{"type":"text","icon":"el-icon-s-promotion","size":"mini"},on:{"click":function($event){return _vm.shelves(row)}}},[_vm._v(_vm._s(row.status == 0 ? "停用" : "启用"))])]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.search.page,"background":"","page-sizes":[3, 5, 10, 15],"page-size":_vm.search.pageSize,"layout":"total, prev, pager, next","total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }